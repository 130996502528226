/* eslint-disable react/no-danger */
import React from 'react';
import { shape } from 'prop-types';

import SEO from '../../components/SEO';
import { withAPI } from '../../utils/API';

import {
  container,
  sidebar,
  main,
  content,
} from './style.module.css';

const Office = ({ data }) => (
  <main className={container}>
    <SEO title="OFFICE" />
    <div className={content}>
      <div className={main} dangerouslySetInnerHTML={{ __html: data.main }} />
      <div className={sidebar} dangerouslySetInnerHTML={{ __html: data.sidebar }} />
    </div>
  </main>
);

Office.propTypes = {
  data: shape({}).isRequired,
};

export default withAPI(Office, '/info');
