/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { func } from 'prop-types';
import useSWR from 'swr';

import SEO from '../../components/SEO';

import ImageItem from '../../components/ImageItem';
import TextItem from '../../components/TextItem';
import LinkItem from '../../components/LinkItem';

import {
  container,
  coverContainer,
  contentContainer,
  desc,
  aside,
  descTitle,
  back,
} from './style.module.css';

const Project = ({ setTitle }) => {
  const { id } = useParams();
  const { data, error } = useSWR(`/project/${id}`);

  useEffect(() => {
    if (data) {
      setTitle(data.title);
    }
  });

  if (error) {
    return (
      <div>
        { process.env.NODE_ENV === 'development' && <b>Error loading</b> }
      </div>
    );
  }

  if (!data) {
    return <div />;
  }

  const {
    title,
    description = false,
    cover,
    content: contents,
    meta,
    listing,
  } = data;

  return (
    <main className={container}>
      <SEO title={title} />

      <div className={coverContainer}>
        <img src={`${cover.media_endpoint}/w_2400/${cover.filename}`} alt={title} />
      </div>

      {
        description && (
          <div className={desc}>
            <h2 className={descTitle}>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: description }} />
          </div>
        )
      }
      {
        meta && (
          <aside
            className={aside}
            dangerouslySetInnerHTML={{ __html: meta }}
          />
        )
      }
      <div className={contentContainer}>
        {
          contents.map(({
            content,
            source,
            title: itemTitle,
            type,
            uuid,
          }, i) => {
            switch (type) {
              case 'Image':
                return <ImageItem key={`${uuid}-${i}`} image={content} />;

              case 'Link':
                return <LinkItem key={`${uuid}-${i}`} source={source} title={itemTitle} />;

              case 'RichText':
              case 'Text':
                return <TextItem key={`${uuid}-${i}`} content={content} />;

              default:
                return null;
            }
          })
        }
      </div>
      <div className={back}>
        <a href={listing === 0 ? '/index' : '/small-works'}>{`BACK TO ${listing === 0 ? 'PROJECT INDEX' : 'SMALL WORKS'}`}</a>
      </div>
    </main>
  );
};

Project.propTypes = {
  setTitle: func.isRequired,
};

export default Project;
