/* eslint-disable react/no-array-index-key */
import React from 'react';
import { arrayOf, shape } from 'prop-types';

import { withAPI } from '../../utils/API';

import SEO from '../../components/SEO';

import ImagePost from './ImagePost';
import TextPost from './TextPost';

import TextWrapper from '../../components/TextWrapper';

import {
  linkItem,
  items,
  social,
  socialLabel,
} from './style.module.css';

const Home = ({ data }) => (
  <main>
    <SEO title="HOME" />
    <div className={items}>
      {
        data.map((item, i) => {
          switch (item.type) {
            case 'IMAGE':
              return <ImagePost key={`post-${i}}`} image={item.images[0]} title={item.title} link={item.link ? item.link : false} />;

            case 'LINK':
              return (
                <div className={linkItem} key={`${item.title}-${item.url}`}>
                  <TextWrapper
                    text={item.title}
                    href={item.url}
                  />
                </div>
              );

            case 'TEXT':
              return <TextPost key={`post-${i}}`} content={item.content} title={item.title} />;

            default:
              return null;
          }
        })
      }
    </div>
    <div className={social}>
      <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/terremoto_landscape/">
        <span className={socialLabel}>instagram</span>
      </a>
    </div>
  </main>
);

Home.propTypes = {
  data: arrayOf(shape({})).isRequired,
};

export default withAPI(Home, '/post');
