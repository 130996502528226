/* eslint-disable react/no-danger */
import React, { useRef, useState, useEffect } from 'react';
import { arrayOf, shape } from 'prop-types';
import { useWindowResize } from 'beautiful-react-hooks';

import SEO from '../../components/SEO';
import { withAPI } from '../../utils/API';

import TextWrapper from '../../components/TextWrapper';

import {
  container,
  item,
} from './style.module.css';

const Writings = ({ data }) => {
  const [columnInfo, setColumnInfo] = useState({
    columnWidth: 0,
    gutter: 0,
    columnCount: 0,
  });
  const [allSizes, setAllSizes] = useState([]);
  const sizes = [];
  const ref = useRef();

  const addSize = (size) => {
    sizes.push(size);
    if (sizes.length === data.length) {
      setAllSizes(sizes);
    }
  };

  const calcColumnSize = () => {
    if (ref && ref.current) {
      try {
        const style = window.getComputedStyle(ref.current);
        const gutter = parseFloat(style.getPropertyValue('grid-column-gap').replace('px', ''), 10);

        const cols = style.getPropertyValue('grid-template-columns').split(' ');
        const columnCount = cols.length;
        const columnWidth = parseFloat(cols[0].replace('px', ''), 10);

        setColumnInfo({
          gutter,
          columnWidth,
          columnCount,
        });
      } catch (e) {
        // trap error
      }
    }
  };

  const onWindowResize = useWindowResize();
  onWindowResize(calcColumnSize);
  useEffect(calcColumnSize, [data]);

  let sizeStyles = data.map(() => ({}));
  if (allSizes.length > 0) {
    const {
      columnWidth,
      gutter,
      columnCount,
    } = columnInfo;
    let lastColumnHeights = data.map(() => (0));
    let columnHeights = data.map(() => (0));
    let lastColumn = 1;
    let currentRow = 1;
    let rowWidth = 0;

    let lastRowHeight = 0;
    let thisRowMaxHeight = 0;

    sizeStyles = allSizes.map(({ width, height }) => {
      let newColumnCount = 1;
      let newWidth = columnWidth;
      do {
        newColumnCount += 1;
        newWidth = (newColumnCount * columnWidth) + ((newColumnCount - 1) * gutter);
      } while (newWidth < width);
      if (rowWidth + newColumnCount > columnCount) {
        lastColumn = 1;
        currentRow += 1;
        rowWidth = 0;
        lastRowHeight = thisRowMaxHeight;
        lastColumnHeights = columnHeights;
        thisRowMaxHeight = 0;
        columnHeights = data.map(() => (0));
      }
      const out = {
        gridColumn: `${lastColumn} / span ${newColumnCount}`,
        gridRowStart: currentRow,
      };

      // determine height
      thisRowMaxHeight = Math.max(thisRowMaxHeight, height);
      if (currentRow === 1) {
        for (let i = (lastColumn - 1); i < lastColumn + newColumnCount; i += 1) {
          columnHeights[i] = height;
        }
      } else {
        let cellAbove = 0;
        for (let i = (lastColumn - 1); i < lastColumn + newColumnCount - 1; i += 1) {
          cellAbove = Math.max(cellAbove, lastColumnHeights[i]);
        }

        const diff = -(lastRowHeight - cellAbove);
        out.top = `${diff}px`;
        for (let i = (lastColumn - 1); i < lastColumn + newColumnCount - 1; i += 1) {
          columnHeights[i] = height + diff;
        }
      }

      rowWidth += newColumnCount;
      lastColumn = rowWidth + 1;

      return out;
    });
  }

  return (
    <main className={container} ref={ref}>
      <SEO title="WRITINGS" />
      {
        data.map(({ title, link }, i) => (
          <div className={item} style={sizeStyles[i]} key={title}>
            <TextWrapper
              text={title}
              href={link}
              sizeCallback={addSize}
            />
          </div>
        ))
      }
    </main>
  );
};

Writings.propTypes = {
  data: arrayOf(shape({})).isRequired,
};

export default withAPI(Writings, '/writing');
